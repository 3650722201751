import React, { useContext } from "react";
import { UsuarioContext } from "../context/UsuarioContext";

const Inicio = () => {
	const { user } = useContext(UsuarioContext);

	return (
		<div>
			BIENVENIDO AUDITOR {user ? user.nombre : ""}
		</div>
	);
};
export default Inicio;
